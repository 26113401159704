import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DiscordService {
  constructor(
    private http: HttpClient
  ) { }

  getDiscordLoginURL(): Observable<any> {
    const url = environment.apiBaseUrl + '/Discord/Login';
    return this.http.get(url).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  getDiscordLoginInfo(code: string, state: string): Observable<any> {
    const url = environment.apiBaseUrl + '/Discord/CallAPI?code=' + code + '&state=' + state;
    return this.http.get(url).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  saveDiscordInfo(payload: any): Observable<any> {
    const url = environment.apiBaseUrl + '/Discord/SaveDiscordInfo';
    return this.http.post(url, payload).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  logout(userId: string): Observable<any> {
    const url = environment.apiBaseUrl + '/Discord/DiscordLogout?userId=' + userId;
    return this.http.post(url, { userId }).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }
}
