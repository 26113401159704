import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as UserReducer from './user/user.reducer';
import * as MentorReducer from './mentor/mentor.reducer';

export interface AppState {
  user: UserReducer.UserState;
  mentor: MentorReducer.MentorState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: UserReducer.reducer,
  mentor: MentorReducer.reducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
