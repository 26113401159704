import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { DiscordService } from './services/discord.service';
import { MentorService } from './services/mentor.service';
import { PostService } from './services/post.service';
import { SubscriptionService } from './services/subscription.service';
import { UserService } from './services/user.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    AuthService,
    DiscordService,
    AuthGuardService,
    MentorService,
    UserService,
    SubscriptionService,
    PostService,
  ],
})
export class CoreModule {}
