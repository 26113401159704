import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Action } from '@ngrx/store';
import { User, UserRequest, UpdateUserRequest } from '../../models/user';

export enum ActionTypes {
  GetUser = '[USER] GetUser',
  LoadUser = '[USER] LoadUser',
  UpdateUser = '[USER] UpdateUser',
  UserUpdated = '[USER] UserUpdated',
  ErrorOccurred = '[USER] ErrorOccurred',
}

export class GetUser implements Action {
  readonly type = ActionTypes.GetUser;

  constructor(public payload: UserRequest) {}
}

export class LoadUser implements Action {
  readonly type = ActionTypes.LoadUser;

  constructor(public payload: User) {}
}

export class UpdateUser implements Action {
  readonly type = ActionTypes.UpdateUser;
  constructor(public payload: UpdateUserRequest) {}
}

export class UserUpdated implements Action {
  readonly type = ActionTypes.UserUpdated;
  constructor(public payload: User) {}
}

export class ErrorOccurred implements Action {
  readonly type = ActionTypes.ErrorOccurred;

  constructor(public payload: { errorMessage: string }) {}
}

export type Types =
  | GetUser
  | LoadUser
  | UpdateUser
  | UserUpdated
  | ErrorOccurred;
