import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Action } from '@ngrx/store';
import { Mentor, MentorData } from '../../models/mentor';

export enum ActionTypes {
  SignupReguested = '[MENTOR] SignupRequested',
  SignupCompleted = '[MENTOR] SignupCompleted',
  SignupError = '[MENTOR] ErrorOccured',
  UpdateMentor = '[MENTOR] UpdateMentor',
  MentorUpdated = '[MENTOR] MentorUpdated',
  ErrorOccurred = '[MENTOR] ErrorOccurred',
}

export class SignupRequested implements Action {
  readonly type = ActionTypes.SignupReguested;

  constructor(public payload: MentorData) {}
}

export class SignupCompleted implements Action {
  readonly type = ActionTypes.SignupCompleted;

  constructor(public payload: Mentor) {}
}

export class SignupError implements Action {
  readonly type = ActionTypes.SignupError;

  constructor(public payload: { errorMessage: string }) {}
}

export class UpdateMentor implements Action {
  readonly type = ActionTypes.UpdateMentor;
  constructor(public payload: MentorData) {}
}

export class MentorUpdated implements Action {
  readonly type = ActionTypes.MentorUpdated;
  constructor(public payload: any) {}
}

export class ErrorOccurred implements Action {
  readonly type = ActionTypes.ErrorOccurred;
  constructor(public payload: { errorMessage: any }) {}
}

export type Types =
  | SignupRequested
  | SignupCompleted
  | SignupError
  | UpdateMentor
  | MentorUpdated
  | ErrorOccurred;
