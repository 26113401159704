import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '../models/IUser'
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginRequest, LoginResponse } from '../models/login.request';
import { RegisterRequest } from '../models/register.request';
import { GetMentorsByUserId } from '../models/mentor';
import { GetMentorsByUserIdResponse } from '../models/mentor';
import { FutHttpClient } from './fut-http-client.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url: string = "https://api20.futalert.co.uk/"
  currentUser: IUser | null
  errorMessage: string

  constructor(private http: FutHttpClient, private httpS: HttpClient, private router: Router) {
    
  }

  // login(payload: LoginRequest): Observable<LoginResponse> {
  //   const url = environment.apiBaseUrl + '/Account/Login';
  //   return this.http.post(url, payload).pipe(
  //     map((res: LoginResponse) => {
  //       localStorage.setItem('jwt_token', res.data.accessToken);
  //       localStorage.setItem('user_email', payload.email);
  //       localStorage.setItem('user_id', res.data.userId.toString());
  //       return res;
  //     }),
  //     catchError((err) => throwError(err))
  //   );
  // }

  public getSession(): IUser | null {
    var currentUserObject = localStorage.getItem('currentUser')

    if (currentUserObject != 'null' && currentUserObject != null) {
      this.currentUser = JSON.parse(currentUserObject)
      return JSON.parse(currentUserObject)
    }
    return null
  }

  async login(userName: string, password: string): Promise<void> {
    this.errorMessage = ''
    var currentUserObject = this.getSession()
    if (currentUserObject != null) {
      localStorage.removeItem('currentUser')
    }

    var params = {
      DeviceToken: '',
      DeviceType: 3,
      UserName: userName,
      Password: password,
    }
    let data = JSON.stringify(params)

    var resultObject = await this.http.callBackEndAsync(
      this.url + 'api/user/adminlogonuser/',
      'POST',
      null,
      data
    )

    if (resultObject != null) {
      if (resultObject.Status.StatusType === 'Ok') {
        var paramsRequest = {
          SessionId: resultObject.SessionId,
          AppVersion: '1.0',
          DeviceType: 3,
        }
        let dataRequest = JSON.stringify(paramsRequest)
        var resultSessionObject = await this.http.callBackEndAsync(
          this.url + 'api/user/CheckAdminUserSession/',
          'POST',
          null,
          dataRequest
        )

        if (resultSessionObject != null) {
          if (resultSessionObject.Status.StatusType == 'Ok') {
            this.currentUser = {
              sessionId: resultObject.SessionId,
              userId: resultSessionObject.UserId,
              userName: userName,
              isBetaUser: resultSessionObject.IsBetaUser,
              SubscriptionType: resultSessionObject.SubscriptionType,
              IsSubscriptionActive: resultSessionObject.IsSubscriptionActive,
              PreferredPlatformId: resultSessionObject.PreferredPlatformId,
              PagingNumberSelected: 10,
            }
            localStorage.setItem(
              'currentUser',
              JSON.stringify(this.currentUser)
            )
          } else if (
            resultSessionObject.Status.StatusType == 'OK_Warning' ||
            resultSessionObject.Status.StatusType == 'OK_Information'
          ) {
            this.handleWarning(resultSessionObject.Status.Errors[0])
          } else {
            this.handleWarning(resultSessionObject.Status.Errors[0])
          }
        }
      } else if (
        resultObject.Status.StatusType == 'OK_Warning' ||
        resultObject.Status.StatusType == 'OK_Information'
      ) {
        this.handleWarning(resultObject.Status.Errors[0])
      } else {
        this.handleWarning(resultObject.Status.Errors[0])
      }
    } else {
      this.errorMessage = 'Login failed. Please try again.'
    }
  }

  handleError(err: any):void {
    this.logout();
    this.http.handleError(err);
    if(this.http.errorMessage != null || this.http.errorMessage != ''){
        this.errorMessage = this.http.errorMessage;
        alert(this.errorMessage);
    }
    else{
        alert("FUT Alert servers is under maintentance. Please try again later.");
    }
    this.router.navigate(['/login']);
}

  handleWarning(err: any): void {
    this.http.handleError(err)
    if (this.http.errorMessage != null || this.http.errorMessage != '') {
      this.errorMessage = this.http.errorMessage
    }
  }

  logout(): void {
    this.currentUser = null
    localStorage.removeItem('currentUser')
    localStorage.removeItem('sessionStartTime')
  }

  isLoggedIn(): boolean {
    var currentUserObject = localStorage.getItem('currentUser')
    if (currentUserObject != 'null' && currentUserObject != null) {
      this.currentUser = JSON.parse(currentUserObject)
      return !!JSON.parse(currentUserObject).sessionId
    }
    return false
  }

  startSession() {
    const startTime = new Date().getTime();
    localStorage.setItem('sessionStartTime', startTime.toString());
  }

  checkSession() {
    const startTime = localStorage.getItem('sessionStartTime');
    const sessionDuration = 60 * 60 * 1000; // 1 saat

    if (startTime) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(startTime);

      if (elapsedTime >= sessionDuration) {
        // Oturum süresi dolmuş, oturumu sil ve /login sayfasına yönlendir
        localStorage.removeItem('sessionId');
        localStorage.removeItem('sessionStartTime');
        console.log('Oturum süresi doldu, oturum silindi.');
        this.router.navigate(['/login']);
      }
      console.log(sessionDuration)
    }
  }

  getMentorsByUserId(payload: any): Observable<GetMentorsByUserIdResponse> {
    const url = environment.apiBaseUrl + '/Mentor/GetMentorsByUserId';
    return this.httpS.post(url, payload).pipe(
      map((res: any) => {
        if (res.data.length) {
          console.log('Res.Data', res.data);
          this.router.navigate(['/mentor']);
        } else {
          this.router.navigate(['/']);
        }
        return res;
      }),
      catchError((err) => throwError(err))
    );
  }

  register(payload: RegisterRequest): Observable<any> {
    const url = environment.apiBaseUrl + '/Account/Register';
    return this.httpS.post(url, payload).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => throwError(err))
    );
  }

  // async loginActions(res: LoginResponse): Promise<any> {
  //   const userId = localStorage.getItem('user_id');
  //   const accessTokenHash = localStorage.getItem('jwt_token');
  //   const payload = { userId, accessTokenHash };
  //   return this.mentorService.getMentorsByUserId(payload).pipe(
  //     map((res) => {
  //       if (res.data) {
  //         console.log('getMentorsByUserId:', res.data);
  //         this.router.navigate['/mentor'];
  //       } else {
  //         this.router.navigate['/'];
  //       }
  //     })
  //   );
  // }

  // logout(): void {
  //   this.router.navigate(['/home']);
  //   localStorage.clear();
  // }
}
