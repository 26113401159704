import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserRequest, User, UpdateUserRequest } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserByEmail(payload: UserRequest): Observable<User> {
    const url = environment.apiBaseUrl + '/Account/GetAccount';
    return this.http.post(url, payload).pipe(
      map((res: User) => res),
      catchError((err) => throwError(err))
    );
  }

  updateUser(payload: UpdateUserRequest): Observable<User> {
    const url = environment.apiBaseUrl + '/Account/UpdateAccount';
    return this.http.post(url, payload).pipe(
      map((res: User) => res),
      catchError((err) => throwError(err))
    );
  }
}
