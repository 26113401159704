import { User } from '../../models/user';
import * as UserActions from './user.actions';
export interface UserState {
  loading: boolean;
  errorMessage: string;
  user: User;
}

export const initialState: UserState = {
  loading: false,
  errorMessage: null,
  user: null,
};
export function reducer(
  state = initialState,
  action: UserActions.Types
): UserState {
  switch (action.type) {
    case UserActions.ActionTypes.GetUser: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case UserActions.ActionTypes.LoadUser: {
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    }

    case UserActions.ActionTypes.UpdateUser: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case UserActions.ActionTypes.UserUpdated: {
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    }

    case UserActions.ActionTypes.ErrorOccurred: {
      return {
        ...state,
        loading: false,
        errorMessage: 'Error Occurred',
      };
    }

    default: {
      return state;
    }
  }
}
