import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Mentor, MentorData } from '../models/mentor';


@Injectable({
  providedIn: 'root',
})
export class MentorService {
  constructor(private http: HttpClient, private router: Router) {}

  register(payload: MentorData): Observable<Mentor> {
    const url = environment.apiBaseUrl + '/Mentor/Register';
    return this.http.post(url, payload).pipe(
      map((res: Mentor) => {
        console.log(res);
        localStorage.setItem('mentor_id', res.data.toString());
        this.router.navigate(['/mentor']);
        return res;
      }),
      catchError((err) => throwError(err))
    );
  }

  getMentorsByUserId(payload: any): Observable<any> {
    const url = environment.apiBaseUrl + '/Mentor/GetMentorsByUserId';
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err) => throwError(err))
    );
  }

  getMentorById(payload: any): Observable<any> {
    const url = environment.apiBaseUrl + '/Mentor/GetMentorById';
    return this.http.post(url, payload).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  getMentorByPageName(pageName): Observable<any> {
    const url = `${environment.apiBaseUrl}/Mentor/GetMentorByPageName?pageName=${pageName}`;
    return this.http.get(url).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  mentorSearch(searchText: string): Observable<any> {
    const url = `${environment.apiBaseUrl}/Mentor/Search?searchText=${searchText}`;
    console.log('URL', url);
    return this.http.get(url).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  getAllSubscriptions(mentorId: any): Observable<any> {
    const url = `${environment.apiBaseUrl}/Mentor/GetAllSubscriptions?mentorId=${mentorId}`;
    return this.http.get(url).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  updateMentor(payload: MentorData): Observable<Mentor> {
    const url = environment.apiBaseUrl + '/Mentor/Update';
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err) => throwError(err))
    );
  }
}
