import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PostService {
    constructor(private http: HttpClient) { }

    getAllPostByMentorId(): Observable<any> {
        const userId = localStorage.getItem('user_id');
        const url = `${environment.apiBaseUrl}/Post/GetAllPostByMentorId?userId=${userId}`;
        return this.http.get(url).pipe(
            map((res: any) => res),
            catchError((err) => throwError(err))
        );
    }
}
