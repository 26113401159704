import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'futalert-admin';

  locale: string = 'en';
  language = localStorage.getItem('language')
    ? localStorage.getItem('language')
    : 'en';

  constructor(private translate: TranslateService) {
    translate.setDefaultLang(this.language);
    this.translate.onLangChange.subscribe((data) => {
      this.locale = data.lang;
      localStorage.setItem('language', data.lang);
      window.location.reload();
    });
  }
}
