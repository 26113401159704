import { Mentor, MentorData } from '../../models/mentor';
import * as MentorActions from './mentor.actions';

export interface MentorState {
  loading: boolean;
  errorMessage: string;
  mentor: Mentor;
}

export const initialState: MentorState = {
  loading: false,
  errorMessage: null,
  mentor: null,
};

export function reducer(
  state = initialState,
  action: MentorActions.Types
): MentorState {
  switch (action.type) {
    case MentorActions.ActionTypes.SignupReguested: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case MentorActions.ActionTypes.SignupCompleted: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        mentor: action.payload,
      };
    }

    case MentorActions.ActionTypes.SignupError: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    }

    case MentorActions.ActionTypes.UpdateMentor: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    }

    case MentorActions.ActionTypes.MentorUpdated: {
      return {
        ...state,
        loading: false,
        mentor: action.payload,
      };
    }

    case MentorActions.ActionTypes.ErrorOccurred: {
      return {
        ...state,
        loading: false,
        errorMessage: 'Error Occurred',
      };
    }

    default: {
      return state;
    }
  }
}
