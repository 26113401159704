import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate(): boolean {
    const token = localStorage.getItem('currentUser');
    if (token) {
      this.router.navigate(['/dashboard']);
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  checkSession() {
    const startTime = localStorage.getItem('sessionStartTime');
    const sessionDuration = 60 * 60 * 1000; // 1 saat

    if (startTime) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(startTime);

      if (elapsedTime >= sessionDuration) {
        // Oturum süresi dolmuş, oturumu sil ve /login sayfasına yönlendir
        localStorage.removeItem('sessionId');
        localStorage.removeItem('sessionStartTime');
        console.log('Oturum süresi doldu, oturum silindi.');
        this.router.navigate(['/login']);
      }
      console.log(sessionDuration)
    }
  }
}
